/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


/* NEW CSS */
html {
  --ion-background-color: #F7F7F7;
}

ion-card {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 3px 20px 0 rgba(0,0,0,0.1);
  color: #1c1c1c;
  margin: 0;
}

.custom-grid {
  --ion-grid-padding: 20px;
}

.header-white {
  background: white;
}

.custom-item {
  --background: #fff;
  --background-focused: #E8E7E7;
  border-bottom: 1px solid #E8E7E7;
  border-top: 1px solid #E8E7E7;
  margin-top: -1px;
  --min-height: 40px;
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0;

  ion-icon {
    color: #0c4ce2;
    width: 20px;
    margin: 0 12px 0 0;
  }

  ion-label, .sc-ion-label-md-h {
    margin: 0;
    text-overflow: initial;
    white-space: initial;
    padding-right: 36px;
  }
}

.password-container {
  display: flex;
  position: relative;

  ion-input {
    padding-right: 40px !important;
  }

  .see-password-btn {
    position: absolute;
    right: 0;
    top: 24px;
    --background: transparent;
    --box-shadow: none;
    color: #000;
    z-index: 2;
    box-shadow: none !important;
  }
}


// HEADER AFTER AUTH
.authHeader {
  height: 55px;

  &__toolbar {
    height: 100%;
  }

  &__item {
    --background: none;
  }

  &__logo {
    width: 96px;
  }

  &__menu {
    color: #000000;
  }

  &__notifications {
    color: #000000;
    font-size: 20px;
  }
}

// SIDE MENU
.sideMenu {
  --width: 100%;

  &__header {
    &__item {
      --padding-start: 0;
      --border-style: none;

      &__logo {
        width: 96px;
        margin: 11px 20px;
      }

      &__buttons {
        width: 35px;
        margin: 0;

        &__icon {
          font-size: 36px;
          color: #000000;
        }
      }
    }
  }

  &__content {
    &__item {
      --detail-icon-opacity: 1;
      --padding-start: 0;
      --inner-padding-bottom: 10px;
      padding: 10px 20px 0 20px;

      &__avatar {
        width: 46px;
        margin-right: 15px;
      }

      &__icon {
        margin-right: 15px;
      }

      &__label {
        font-family: 'Gotham Book' !important;
        font-size: 16px !important;
      }
    }
  }
}

.pageToolbar {
  margin-top: 10px;

  &__title {
    font-family: "Gotham Book";
    font-size: 18px;
    font-weight: normal;
    padding: 0 60px;
  }
}

.checkbox-container {
  //margin-top:16px;
  display: flex;
  font-family: 'Gotham Book';

  .confirmmsg {
    font-size: 14px;
    a {
      font-family: 'Gotham Book';
      color: #005EB8;
    }
  }

  .chkboxclass {
    --border-radius: 0;
    margin-right: 12px;
    margin-top: 20px;
    height: 18px;
    width: 18px;
  }
}
// BOOST ADVISER STEPS
.statusBar {
  display: flex;
  justify-content: space-between;
  border-top: 3px solid #DCDCDC;
  padding-top: 13px;
  margin: 30px auto;

  div {
    background-color: white;
    font-weight: bold;
    border: 2px solid #DCDCDC;
    margin-top: -23px;
    width: 18px;
    height: 18px;
    line-height: 45px;
    text-align: center;
    border-radius: 50%;
  }
}


.default-btn {
  --background: #0C4CE2;
  --border-radius: 25px;
  color: #ffffff;
  height: 46px;
  font-size: 18px;
  font-weight: bold;
  margin-top: 30px;
  letter-spacing: 0;
  font-family: 'Helvetica Neue', sans-serif;
  --box-shadow: none;
  width: auto;
}

ion-toolbar {
  --background: rgba(12, 76, 226, .9);
  color: #fff;
  --min-height: 80px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url('assets/images/background-image.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  ion-title{
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    font-weight: bold;
  }
}

.header-md::after {
  display: none;
}


.menu-button {
  ion-icon { width: 25px; color: #000; }
}

.ld-container{
  width: 100%;
  min-height: 100%;
  background: url('assets/images/background-image.png') no-repeat top;
  background-size: cover;

  &__inner{
    max-width: 500px;
    padding: 10px 32px 32px 32px;
    font-size: 15px;
    margin: 0 auto;
  }

  &__logo{
    display: block;
    margin: 50px auto 32px auto;
    max-height: 35vh;
    width: 237px;
  }
}

.ld-form{
  background: #ffffff;
  border: 1px solid #707070;
  padding: 24px;

  h1 {
    font-size: 32px;
    color: var(--main-text-color);
    text-transform: uppercase;
    margin: 0 0 24px;
    font-family: "Poppins Bold", sans-serif;
  }

  ion-input {
    border: 1px solid #EEEEEE;
    height: 50px;
    padding: 16px 24px !important;
    font-size: 16px !important;
    color: var(--main-text-color);
    margin-top: 24px;
    font-family: Roboto, sans-serif;
  }

  ion-checkbox {
    --border-color: #8080804D;
    --border-width: 1px;
    --border-color-checked: #8080804D;
    --background-checked: #0C4CE2;
  }

  ion-label {
    font-family: Roboto, sans-serif;
  }

  a {
    color: #0C4CE2;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    font-family: "Roboto Medium", sans-serif;
  }

  &__footer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 12px;
  }
}

.info{
  line-height: 1.4;
  margin-top: 0;
  font-size: 0.9rem;
  span {
    font-weight: bold;
    color:#0C4CE2;

  }

  &-actions{
    a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-left: 4px;
      margin-right: 4px;

      ion-icon{
        margin-right: 3px;
      }
    }
    a.resend-btn {
      color:#eb445a;
    }
  }
}

.text-white{ color:#fff;}

.error-message{
  font-weight: 500;
  color:#ce1717;
}
